import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Школа англійської Evergreen
			</title>
			<meta name={"description"} content={"Школа англійської Evergreen - професійні заняття з англійської мови для всіх рівнів. Поліпшіть свої знання англійської з нами."} />
			<meta property={"og:title"} content={"Школа англійської Evergreen"} />
			<meta property={"og:description"} content={"Школа англійської Evergreen - професійні заняття з англійської мови для всіх рівнів. Поліпшіть свої знання англійської з нами."} />
			<meta property={"og:image"} content={"https://vorix-caita.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vorix-caita.com/img/325356456.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vorix-caita.com/img/325356456.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vorix-caita.com/img/325356456.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наші послуги
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
				>
					У школі англійської Evergreen ми пропонуємо різноманітні курси англійської мови для задоволення потреб кожного студента. Наші програми розроблені для різних рівнів володіння мовою та орієнтовані на досягнення конкретних цілей. Давайте розглянемо детальніше наші послуги.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				flex-direction="row"
				sm-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					sm-width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Курси для початківців
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Курси для початківців призначені для тих, хто тільки починає вивчати англійську мову. Ми допоможемо вам освоїти основи граматики, лексики та розвитку розмовних навичок. Ви навчитеся базовим виразам, які допоможуть вам спілкуватися у повсякденних ситуаціях.
					</Text>
				</Box>
				<Box
					width="50%"
					sm-width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Курси середнього рівня
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Курси середнього рівня спрямовані на тих, хто вже має базові знання англійської і хоче їх поглибити. Ми зосередимося на розширенні вашого словникового запасу, удосконаленні граматики та розвитку навичок спілкування. Наші інтерактивні заняття допоможуть вам вільно спілкуватися на різні теми.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				flex-direction="row"
				sm-flex-direction="column"
				align-items="flex-start"
				md-justify-content="center"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="50%"
					sm-width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Курси для просунутих
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Курси для просунутих студентів допоможуть вам вдосконалити ваші навички та досягти високого рівня володіння англійською мовою. Ми пропонуємо інтенсивні програми, що включають розбір складних граматичних структур, розширення словникового запасу та вдосконалення навичок письма та розмовної мови.
					</Text>
				</Box>
				<Box
					width="50%"
					sm-width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Підготовка до іспитів
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Ми пропонуємо спеціалізовані курси для підготовки до міжнародних іспитів, таких як IELTS, TOEFL та Cambridge Exams. Наші викладачі допоможуть вам підготуватися до всіх частин іспиту, включаючи читання, письмо, аудіювання та говоріння. Ви отримаєте практичні поради та стратегії для успішного складання іспиту.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				flex-direction="row"
				sm-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
			<Box
				width="50%"
				sm-width="100%"
				display="flex"
				align-items="flex-start"
				justify-content="flex-start"
				md-border-width="0px"
				flex-direction="column"
				padding="16px 12px 16px 12px"
			>
				<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
				Бізнес-англійська
				</Text>
				<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
				Ми пропонуємо спеціалізовані курси для підготовки до міжнародних іспитів, таких як IELTS, TOEFL та Cambridge Exams. Наші викладачі допоможуть вам підготуватися до всіх частин іспиту, включаючи читання, письмо, аудіювання та говоріння. Ви отримаєте практичні поради та стратегії для успішного складання іспиту.
				</Text>
			</Box>
				<Box
					width="50%"
					sm-width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
					margin="20px"
					box-sizing="border-box"
				>
					<Image
						src="https://vorix-caita.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="rgba(238, 205, 108, 0.7)"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Запишіться на наші курси вже сьогодні!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Обирайте курс, який відповідає вашим потребам, і почніть покращувати свої знання англійської мови зі школою англійської Evergreen. Наші викладачі допоможуть вам досягти успіху.
				</Text>
				<Link
					href="/contact"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-red"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
					target="_blank"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://vorix-caita.com/img/7.jpg"
					object-fit="cover"
					max-height="600px"
					width="100%"
					object-position="0% 0%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});